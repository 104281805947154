



























import BaseButton from '@/components/base/BaseButton.vue';
import { useAuthStore } from '@/composables/useAuthStore';
import { useBookedResources } from '@/composables/useBookedResources';
import MemberPage from '@/layouts/MemberLayout/MemberPage.vue';
import { Resource } from '@/models/booked/Resource';
import { defineComponent, onMounted, ref } from '@/plugins/composition';
import { routeNames } from '@/router/routeNames';

export default defineComponent({
  components: { BaseButton, MemberPage },
  name: 'ViewSpacesPage',
  setup() {
    const resources = ref<Resource[]>([]);

    const { getResources } = useBookedResources();

    const { isMember } = useAuthStore();

    const loadResources = async () => {
      resources.value = await getResources();
    };

    onMounted(() => {
      loadResources();
    });

    return {
      resources,
      isMember,
      routeNames,
    };
  },
});
